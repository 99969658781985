<style scoped>
.customer-tab-wrapper {
  min-width: 100px;
}
.active-tab {
  /* background-color: #358cff; */
  background-color: var(--tab-color);
  color: #fff !important;
}
.customer-tab_number {
  font-size: 25px;
  font-weight: 700;
  text-align: left;
}
.customer-tab_text {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
#status-v-chip .v-chip {
  font-size: 12px;
  font-weight: 800;
}
/* task chip styling */
#status-v-chip .v-chip.Application,
#status-v-chip .v-chip.Pending {
  background: #faf9e0 !important;
  color: #b5ae00 !important;
}
#status-v-chip .v-chip.eKYC {
  background: #e7f7fb !important;
  color: #31bee8 !important;
}
#status-v-chip .v-chip.Kyc {
  background: #e7f7fb !important;
  color: #31bee8 !important;
}
#status-v-chip .v-chip.Mandate {
  background: #f8eefe !important;
  color: #b554f1 !important;
}
#status-v-chip .v-chip.Approved {
  background: #f8eefe !important;
  color: #b554f1 !important;
}
#status-v-chip .v-chip.vKYC {
  background: #e7eff6 !important;
  color: #337ab7 !important;
}
#status-v-chip .v-chip.Nach,
#status-v-chip .v-chip.Deviated {
  background: #e7eff6 !important;
  color: #337ab7 !important;
}
#status-v-chip .v-chip.Rejected {
  background: #feebf0 !important;
  color: #ff5b8c !important;
}
#status-v-chip .v-chip.KYC {
  background: #fbe6f5 !important;
  color: #ef2cb9 !important;
}
#status-v-chip .v-chip.Statement {
  background: #fbe6f5 !important;
  color: #ef2cb9 !important;
}
#status-v-chip .v-chip.Money {
  background: #fdf2e3 !important;
  color: #f0ad4e !important;
}
#status-v-chip .v-chip.Review {
  background: #fdf2e3 !important;
  color: #f0ad4e !important;
}
#status-v-chip .v-chip.Disbursed,
#status-v-chip .v-chip.Sanctioned {
  background: #def1de !important;
  color: #5cb85c !important;
}
.status-updated-text {
  font-size: 10px;
}
</style>
<template>
  <div>
    <!-- filters -->
    <v-expansion-panels class="mb-4 px-3">
      <v-expansion-panel class="neumorphism-pressed">
        <v-expansion-panel-header>
          Filters
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-1 mx-0">
            <v-col class="text-center" cols="12" md="3">
              <v-menu
                v-model="date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filters.display_daterange"
                    v-on="on"
                    class="filter"
                    dense
                    filled
                    label="Filter by Signup Date Range"
                    outlined
                    placeholder="Filter by Date Range"
                    readonly
                    single-line
                  >
                    <template slot="append">
                      <v-icon
                        v-if="filters.date_range.length > 1"
                        @click="clearDateFilter()"
                        >mdi-close</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="filters.date_range" :max="today" range>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="
                      filters.date_range = [];
                      date_menu = false;
                    "
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="formateDisplayDate"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="text-center" cols="12" md="3">
              <v-combobox
                v-model="filters.current_city"
                :items="city_list"
                :loading="city_loading"
                :search-input.sync="city_search"
                clearable
                dense
                filled
                hide-details
                label="Select city"
                outlined
                placeholder="Select city"
                single-line
                @change="getCustomerList(true)"
                @focus="getCitylist"
                @keyup="getCitylist"
              >
                <template v-slot:append-item>
                  <div v-intersect="endIntersect"></div>
                </template>
              </v-combobox>
            </v-col>
            <v-col class="text-center" cols="12" md="3">
              <v-combobox
                v-model="filters.state"
                :items="states_list"
                :search-input.sync="state_search"
                clearable
                dense
                filled
                hide-details
                label="Select state"
                outlined
                placeholder="Select state"
                single-line
                @change="getCustomerList(true)"
              >
              </v-combobox>
            </v-col>
            <v-col class="text-center" cols="12" md="3">
              <v-menu
                v-model="fd_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filters.fd_display_date"
                    v-on="on"
                    :label="$lang.FOLLOW_UP_DATE"
                    :placeholder="$lang.FOLLOW_UP_DATE"
                    class="filter"
                    dense
                    filled
                    outlined
                    readonly
                    single-line
                  >
                    <template slot="append">
                      <v-icon
                        v-if="filters.follow_up_date"
                        @click="
                          filters.follow_up_date = null;
                          filters.fd_display_date = null;
                          clearDateFilter();
                        "
                        >mdi-close
                      </v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="filters.follow_up_date">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="date_menu = false"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="formateDisplayDate"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="text-center pt-0" cols="12" md="3">
              <v-menu
                v-model="status_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filters.display_status_daterange"
                    v-on="on"
                    class="filter"
                    dense
                    filled
                    label="Filter by Applied date"
                    outlined
                    placeholder="Filter by Applied date"
                    readonly
                    single-line
                  >
                    <template slot="append">
                      <v-icon
                        v-if="filters.status_date_range.length > 1"
                        @click="clearStatusDateFilter()"
                        >mdi-close</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.status_date_range"
                  :max="today"
                  range
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="
                      filters.status_date_range = [];
                      status_date_menu = false;
                    "
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="formateDisplayDate"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="text-center pt-0" cols="12" md="3">
              <v-select
                :items="agent_type_list"
                item-value="value"
                item-text="text"
                v-model="filters.agent_type"
                class="filter"
                dense
                filled
                label="Select Agent Type"
                outlined
                placeholder="Select Agent Type"
                clearable
              >
              </v-select>
            </v-col>
            <v-col
              v-if="filters.agent_type"
              class="text-center pt-0"
              cols="12"
              md="3"
            >
              <v-combobox
                v-model="filters.agent_assigned"
                :items="agent_list"
                :loading="agent_loading"
                :search-input.sync="agent_search"
                clearable
                dense
                filled
                hide-details
                item-text="name"
                item-value="id"
                label="Select agent"
                outlined
                placeholder="Select agent"
                single-line
                @focus="getAgentList"
                @keyup="getAgentList"
                @change="getCustomerList(true)"
              >
                <template v-slot:append-item>
                  <div v-intersect="endIntersectAgent"></div>
                </template>
              </v-combobox>
            </v-col>
            <v-col class="text-center pt-0" cols="12" md="3">
              <v-select
                :items="document_type_list"
                item-value="value"
                item-text="text"
                v-model="filters.document_type"
                class="filter"
                dense
                filled
                label="Select Document Type"
                outlined
                placeholder="Select Agent Type"
                clearable
                @change="clearDocRequestStatus()"
              >
              </v-select>
            </v-col>
            <v-col
              v-if="filters.document_type"
              class="text-center pt-0 mb-5"
              cols="12"
              md="3"
            >
              <v-select
                :items="document_request_status_list"
                item-value="value"
                item-text="text"
                v-model="filters.document_request_status"
                class="filter"
                dense
                filled
                label="Select Doc Status"
                outlined
                placeholder="Select Doc Status"
                clearable
                @change="getCustomerList(true)"
              ></v-select>
              <!-- </v-select> -->
            </v-col>
            <v-col class="text-center pt-0" cols="12" md="3">
              <v-select
                :items="nbfc_list"
                item-value="nbfc_name"
                item-text="nbfc_name"
                v-model="filters.alloted_nbfc"
                class="filter"
                dense
                filled
                label="Select NBFC"
                outlined
                placeholder="Select NBFC"
                clearable
                :loading="nbfc_list_loader"
                @focus="getNbfcList()"
                @change="getCustomerList(true)"
              ></v-select>
              <!-- </v-select> -->
            </v-col>
            <v-col
              class="text-center pt-0"
              cols="12"
              md="3"
              v-if="
                filters.alloted_nbfc == 'LTFS' &&
                  filters.precheck_status == 'Approved By NBFC'
              "
            >
              <v-select
                :items="nbfc_filter_list"
                v-model="filters.nbfc_status_filter"
                class="filter"
                dense
                filled
                label="Select NBFC  Status"
                outlined
                placeholder="Select NBFC status"
                clearable
                @change="getCustomerList(true)"
              ></v-select>
              <!-- </v-select> -->
            </v-col>
            <v-col
              class="text-center pt-0"
              cols="12"
              md="3"
              v-if="
                filters.alloted_nbfc &&
                  filters.precheck_status == 'Approved By NBFC'
              "
            >
              <v-select
                :items="perfios_filter_list"
                v-model="filters.perfios_status"
                class="filter"
                dense
                filled
                label="Select Perfios"
                outlined
                placeholder="Select Perfios"
                clearable
                @change="getCustomerList(true)"
              ></v-select>
              <!-- </v-select> -->
            </v-col>
            <v-col class="text-center" cols="12" md="3">
              <v-menu
                v-model="disbursed_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filters.display_disbursed_daterange"
                    v-on="on"
                    class="filter"
                    dense
                    filled
                    label="Filter by Disbursed Date Range"
                    outlined
                    placeholder="Filter by Disbursed Date Range"
                    readonly
                    single-line
                  >
                    <template slot="append">
                      <v-icon
                        v-if="filters.disbursed_date_range.length > 1"
                        @click="clearDisbursedDateFilter()"
                        >mdi-close</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.disbursed_date_range"
                  :max="today"
                  range
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="
                      filters.disbursed_date_range = [];
                      date_menu = false;
                    "
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary"
                    text
                    @click="formatDisplayDisbursedDate"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div>
      <!-- tabs -->
      <v-tabs show-arrows v-model="filters.selected_tab" height="95px">
        <v-tab
          v-for="(item, id) in tabs_title"
          :key="id"
          class="customer-tab"
          active-class="active-tab"
          :style="cssValue"
          @click="tabClicked(item)"
        >
          <div class="customer-tab-wrapper">
            <div class="customer-tab_number">
              <!-- {{ parseInt(item.value).toLocaleString("en-IN") }} -->
              <div v-if="item.count">
                {{ item.count.toLocaleString("en-IN") }}
              </div>
              <div v-else>0</div>
              <!-- {{item.param}} -->
              <!-- {{ item.color }} -->
            </div>
            <div class="customer-tab_text">
              {{ item.title }}
            </div>
          </div>
        </v-tab>
      </v-tabs>

      <!-- tab content -->
      <div class="mt-6">
        <!-- <div v-else> -->
        <div>
          <!-- :headers="is_rejected_table ? rejectHeaders : headers" -->
          <v-data-table
            :disable-sort="true"
            :headers="headers"
            :items="customer_list"
            :loading="table_loading"
            :items-per-page="$keys.PAGE_LENGTH"
            class="border-a border-0 custome-header-bg"
            hide-default-footer
            loading-text="Loading...."
          >
            <template v-slot:progress>
              <v-progress-linear
                bottom
                indeterminate
                class="lighten1"
                style="width: 103%; margin-left: -20px"
              >
              </v-progress-linear>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <p class="pt-2">{{ item.name }}</p>
              <p class="py-1">{{ item.mobile }}</p>
              <p class="pb-2">{{ item.email }}</p>
              <v-chip
                v-if="item.pushed_to_ltfs"
                outlined
                color="success"
                class="mb-2"
              >
                Pushed To LTFS
              </v-chip>
            </template>
            <template v-slot:[`item.follow_up_date`]="{ item }">
              <div v-if="item.follow_up_date">{{ item.follow_up_date }}</div>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.applied_amount`]="{ item }">
              <div v-if="item.applied_amount">
                ₹{{ item.applied_amount.toLocaleString("en-IN") }}
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.disbursed_amount`]="{ item }">
              <div v-if="item.disbursed_amount">
                ₹{{ item.disbursed_amount.toLocaleString("en-IN") }}
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.alloted_nbfc`]="{ item }">
              <div v-if="item.alloted_nbfc">{{ item.alloted_nbfc }}</div>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.offer_details`]="{ item }">
              <div class="pb-2" v-if="item.alloted_nbfc == 'LTFS'">
                <div v-if="item.eligibility_amount">
                  <p class="pt-2">
                    <span v-if="item.eligibility_amount">
                      ₹{{ item.eligibility_amount.toLocaleString("en-IN") }}
                    </span>
                    <span v-else>-</span>
                  </p>
                  <p class="py-1">{{ item.irr }}%</p>
                  <p class="pb-2">
                    {{ item.min_tenure }} - {{ item.max_tenure }} Months(s)
                  </p>
                </div>
                <div v-else>-</div>
              </div>
              <!-- <div class="pb-2" v-if="item.alloted_nbfc == 'Fibe'">
                <div v-if="item.fibe_offer">
                  <p class="pt-2">
                    <span v-if="item.fibe_offer.loan_upto">
                      {{ item.fibe_offer.loan_upto }}
                    </span>
                    <span v-else>-</span>
                  </p>
                  <p class="py-1">{{ item.fibe_offer.interest_rate }}</p>
                  <p class="pb-2">
                    {{ item.fibe_offer.tenure }}
                  </p>
                </div>
                <div v-else>-</div>
              </div> -->
              <div
                v-if="item.alloted_nbfc == 'Banksathi' && item.banksathi_offer"
                class="pb-2"
              >
                <div v-if="item.banksathi_offer.pl_offer_count" class="my-2">
                  <v-chip
                    color="cyan lighten-4"
                    class="font-weight-medium"
                    @click="
                      openOffersList(
                        'Personal Loan',
                        item.banksathi_offer.offer_list.pl_offer_list
                      )
                    "
                  >
                    PL: <b> {{ item.banksathi_offer.pl_offer_count }}</b>
                  </v-chip>
                </div>
                <div v-if="item.banksathi_offer.cc_offer_count">
                  <v-chip
                    color="purple lighten-4"
                    class="font-weight-medium"
                    @click="
                      openOffersList(
                        'Credit Card',
                        item.banksathi_offer.offer_list.cc_offer_list
                      )
                    "
                  >
                    CC: <b> {{ item.banksathi_offer.cc_offer_count }}</b>
                  </v-chip>
                </div>
              </div>
              <div class="pb-2" v-if="item.nbfc_offer.title">
                <div>
                  <p class="pt-2">
                    <span v-if="item.nbfc_offer.loan_upto">
                      <b>LA: </b>{{ item.nbfc_offer.loan_upto }}
                    </span>
                    <span v-else>-</span>
                  </p>
                  <p class="py-1" v-if="item.nbfc_offer.interest_rate">
                    <b>IR:</b> {{ item.nbfc_offer.interest_rate }}
                  </p>
                  <p class="pb-2"><b>T:</b> {{ item.nbfc_offer.tenure }}</p>
                </div>
              </div>
              <div id="perfios-v-chip" class="mb-2">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <v-btn color="primary" dark v-bind="atts -->
                    <v-chip
                      v-if="item.perfios_status"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      :color="
                        item.perfios_status == 'Non Perfios'
                          ? 'light-green darken-3'
                          : 'purple darken-3'
                      "
                    >
                      {{ item.perfios_status }}
                    </v-chip>
                    <v-chip
                      v-else
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      color="secondary_2"
                    >
                      +
                    </v-chip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(perfios, index) in perfios_list"
                      :key="index"
                      @click="updatePrefuse(perfios, item)"
                    >
                      <v-list-item-title>{{ perfios }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
            <template v-slot:[`item.nbfc_stages`]="{ item }">
              <template v-if="item.nbfc_stages">
                <div v-if="item.alloted_nbfc == 'LTFS'" id="status-v-chip">
                  <v-chip
                    v-if="getCurrentStatus(item.nbfc_stages)"
                    :class="getCurrentStatus(item.nbfc_stages)"
                    @click="openNbfcStatusDialog(item)"
                    >{{ getCurrentStatus(item.nbfc_stages) }}</v-chip
                  >
                  <!-- <v-btn
                  color="purple"
                  fab
                  small
                  text
                  outlined
                  @click="openNbfcStatusDialog(item)"
                  class="mr-1"
                >
                  <v-icon>mdi-list-status</v-icon>
                </v-btn> -->
                </div>
                <div v-else-if="item.alloted_nbfc == 'Fibe'" id="status-v-chip">
                  <v-chip
                    v-if="item.fibe_offer.fibe_loan_status"
                    :class="item.fibe_offer.fibe_loan_status"
                    >{{ item.fibe_offer.fibe_loan_status }}</v-chip
                  >
                </div>
              </template>
              <template v-if="item.nbfc_loan_status">
                <div id="status-v-chip">
                  <v-chip
                    class="mt-1"
                    @click="openUpdateNbfcStatusDialog(item)"
                    :class="item.nbfc_loan_status"
                    >{{ item.nbfc_loan_status }}</v-chip
                  >
                  <div
                    class="status-updated-text mt-1"
                    v-if="item.last_nbfc_status_updated_at"
                  >
                    {{ item.last_nbfc_status_updated_at }}
                  </div>
                </div>
                <!-- <v-chip @click="openUpdateNbfcStatusDialog(item)" >
                  {{ item.nbfc_loan_status }}
                </v-chip> -->
              </template>
              <template v-if="!item.nbfc_loan_status">
                <v-btn
                  small
                  dense
                  fab
                  text
                  outlined
                  class="mt-1"
                  @click="openUpdateNbfcStatusDialog(item)"
                >
                  <v-icon color="purple">mdi-plus</v-icon>
                </v-btn>
              </template>
            </template>
            <template v-slot:[`item.agent_assigned`]="{ item }">
              <!-- v-if="
                  is_rejected_table || is_disbursed_or_completed || is_pending
                " -->
              <div
                v-if="
                  filters.precheck_status == 'Precheck Applied' ||
                    filters.precheck_status == 'Approved By NBFC'
                "
              >
                <p
                  v-if="item.agent_assigned"
                  class="cursor-pointer"
                  @click="openAssignAgent(item)"
                >
                  {{ item.agent_assigned }}
                </p>
                <p
                  v-else
                  class="blue--text text-caption cursor-pointer"
                  @click="openAssignAgent(item)"
                >
                  <v-icon x-small color="blue">mdi-pencil</v-icon>
                  <span class="text-decoration-underline">Assign Agent</span>
                </p>
              </div>
              <div v-else>
                <p v-if="item.agent_assigned">{{ item.agent_assigned }}</p>
                <p v-else>-</p>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                color="secondary_2"
                fab
                small
                text
                @click="addComment(item)"
                class="mr-1"
              >
                <v-icon>mdi-message-text-outline</v-icon>
              </v-btn>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    fab
                    v-bind="attrs"
                    v-on="on"
                    small
                    text
                    class="mr-1"
                    v-if="filters.precheck_status == 'Approved By NBFC'"
                    @click="openRejectPendingLeadDialog(item)"
                  >
                    <v-icon>mdi-cancel</v-icon>
                  </v-btn>
                </template>
                <span>Move to Reject</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    text
                    class="mr-1"
                    v-if="
                      filters.precheck_status == 'Approved By NBFC' ||
                        filters.precheck_status == 'Rejected By NBFC'
                    "
                    @click="openMovetoDisbursedDialog(item)"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </template>
                <span>Move to Disbursed</span>
              </v-tooltip>

              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    text
                    class="mr-1"
                    v-if="filters.precheck_status == 'Rejected By NBFC'"
                    @click="openPushToFibeDialog(item)"
                  >
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </template>
                <span>Push to Fibe</span>
              </v-tooltip> -->
              <v-btn
                color="purple"
                fab
                small
                text
                class="mr-1"
                v-if="
                  filters.precheck_status == 'Precheck Applied' ||
                    filters.precheck_status == 'Pending By NBFC' ||
                    filters.precheck_status == 'Rejected By Cashpo' ||
                    filters.precheck_status == 'Rejected By NBFC'
                "
                @click="openPushToLtfsDialog(item)"
              >
                <v-icon>mdi-send-variant</v-icon>
              </v-btn>
              <v-btn fab small text @click="viewNewCustomerDetails(item)">
                <v-icon class="primary_2--text">
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <v-pagination
      v-model="filters.page_number"
      :length="total_page_count"
      :total-visible="7"
      class="custome-pagination"
      @input="getCustomerList(true)"
    ></v-pagination>

    <AddComments
      ref="comment_dialog"
      :dialog="comment_dialog"
      @update="getCustomerList"
    />

    <PreVerificationDialog
      v-if="pre_verification_dialog_data.flag"
      :dialog="pre_verification_dialog_data"
    ></PreVerificationDialog>
    <AssignAgentDialog
      v-if="assign_agent_dialog.flag"
      :dialog="assign_agent_dialog"
      @update="updateAgent"
    />
    <NbfcStatusDialog ref="nbfc_status_dialog" />
    <RejectPendingLead
      ref="reject_pending_lead_dialog"
      @update="getCustomerList"
    />
    <PushToLtfs ref="push_to_ltfs_dialog" @update="getCustomerList" />
    <PushToFibe ref="push_to_fibe" @update="getCustomerList" />
    <OffersList ref="offers_list" />
    <MoveToDisbursed ref="move_to_disbursed_dialog" @update="getCustomerList" />
    <UpdateNbfcLoanStatus
      ref="update_nbfc_loan_status_dialog"
      @update="getCustomerList"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    AddComments: () => import("@/components/customer/dialog/AddComments"),
    PreVerificationDialog: () =>
      import("@/components/pre-verification/PreVerificationDialog.vue"),
    NbfcStatusDialog: () =>
      import("@/components/pre-verification/dialog/NbfcStatus.vue"),
    AssignAgentDialog: () =>
      import("@/components/loan-applied/AssignAgentDialog"),
    RejectPendingLead: () =>
      import("@/components/pre-verification/dialog/RejectPendingLead"),
    PushToLtfs: () => import("@/components/common/PushToLtfs.vue"),
    PushToFibe: () =>
      import("@/components/pre-verification/dialog/PushToFibe.vue"),
    OffersList: () =>
      import("@/components/pre-verification/dialog/OfferList.vue"),
    MoveToDisbursed: () =>
      import("@/components/pre-verification/dialog/MoveToDisbursed"),
    UpdateNbfcLoanStatus: () =>
      import("@/components/pre-verification/dialog/UpdateNbfcLoanStatus.vue"),
  },
  data() {
    return {
      tabColor: "",
      PreVerificationDialogloading: false,
      activeTab: "",
      profile_step: "",
      fd_date_menu: false,

      is_rejected_table: false,

      loans: [],
      table_loading: false,
      page_number: 1,
      total_page_count: 2,

      customer_list: [],

      tabs_title: [],

      active_table: "",

      date_menu: false,
      display_daterange: null,
      today: new Date().toISOString().substr(0, 10),
      city_list: [],
      city_search_query: "",
      city_loading: false,
      city_page: 1,
      city_search: "",
      state_search: "",
      pre_verification_dialog_data: {
        flag: false,
        data: [],
      },
      comment_dialog: {
        flag: false,
        comment: null,
        follow_up_date: null,
        label: null,
        id: null,
        name: "",
        mobile: "",
        email: "",
      },
      assign_agent_dialog: {
        flag: false,
        loan_id: null,
        agent: "",
      },

      agent_list: [],
      agent_loading: false,
      agent_page: 1,
      agent_search: "",
      agent: "",
      status_date_menu: false,
      // agent_type: "",
      agent_type_list: [
        {
          id: 1,
          text: "Assigned",
          value: true,
        },
        {
          id: 2,
          text: "Unassigned",
          value: false,
        },
      ],
      // document_type: "",
      document_type_list: [
        {
          id: 1,
          text: "Rent Address Proof",
          value: "address_proof",
        },
        {
          id: 2,
          text: "Bank Statement",
          value: "bank_statement",
        },
        {
          id: 3,
          text: "Both",
          value: "both",
        },
      ],
      // document_request_status: "",
      states_list: [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Chattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry",
      ],
      is_pending: false,
      is_disbursed_or_completed: false,
      nbfc_list_loader: false,
      nbfc_list: [],
      perfios_list: ["Perfios Only", "Non Perfios"],
      perfios_filter_list: ["Perfios Only", "Non Perfios", "Unassigned"],
      nbfc_filter_list: [
        "Application Form",
        "Offer details",
        "eKYC",
        "Mandate Setup",
        "vKyc",
        "Money on the Way",
        "Disbursed",
      ],
      disbursed_date_menu: false,
    };
  },
  computed: {
    ...mapGetters({
      search_query: "getSearchText",
      filters: "pre_verification/getFilters",
    }),
    headers() {
      if (
        this.filters.precheck_status == "Rejected By Cashpo" ||
        this.filters.precheck_status == "Rejected By NBFC"
      ) {
        // this.is_rejected_table = true;
        return this.$pre_verification_headers.reject_headers;
      } else if (
        this.filters.precheck_status == "Disbursed By NBFC" ||
        this.filters.precheck_status == "Completed By NBFC"
      ) {
        // this.is_rejected_table = true;
        return this.$pre_verification_headers.disbursed_completed_by_nbfc;
      } else if (this.filters.precheck_status == "Pending By NBFC") {
        // this.is_rejected_table = true;
        // return this.$pre_verification_headers.pending_by_nbfc;
        return this.$pre_verification_headers.pending_by_nbfc;
      } else if (this.filters.precheck_status == "Approved By NBFC") {
        // this.is_rejected_table = true;
        return this.$pre_verification_headers.approved_by_nbfc;
      } else {
        return this.$pre_verification_headers.headers;
      }
    },
    cssValue() {
      return {
        "--tab-color": this.tabColor,
      };
    },
    itemsWithSno() {
      return this.customer_list.map((d, index) => ({
        ...d,
        sno: index + 1,
      }));
    },
    document_request_status_list() {
      if (this.filters.document_type == "both") {
        let list = [
          {
            id: 1,
            text: "Not Requested",
            value: "Not Requested",
          },
          {
            id: 2,
            text: "Requested",
            value: "Requested",
          },
          {
            id: 3,
            text: "Uploaded",
            value: "Uploaded",
          },
          {
            id: 4,
            text: "Partial Uploaded",
            value: "Partial Uploaded",
          },
        ];
        return list;
      } else {
        let list = [
          {
            id: 1,
            text: "Not Requested",
            value: "Not Requested",
          },
          {
            id: 2,
            text: "Requested",
            value: "Requested",
          },
          {
            id: 3,
            text: "Uploaded",
            value: "Uploaded",
          },
        ];
        return list;
      }
    },
  },
  watch: {
    search_query(new_search_query, old_search_query) {
      this.getCustomerList(true);
    },
    "filters.agent_type": function(value) {
      if (!value) {
        this.filters.agent_assigned = "";
        this.getCustomerList(true);
      }
    },
    "filters.alloted_nbfc": function() {
      this.filters.perfios_status = "";
      this.filters.nbfc_status_filter = "";
    },
    "filters.precheck_status": function() {
      this.filters.perfios_status = "";
      this.filters.nbfc_status_filter = "";
    },
  },

  created() {
    // console.log(this.$route.meta.show_back_btn);
    this.getCustomerList(true);
  },

  // destroyed() {
  //   this.filters.agent_assigned = "";
  // },
  methods: {
    openPreVerificationDialog(item) {
      this.pre_verification_dialog_data.flag = true;
      this.pre_verification_dialog_data.data = item;
      this.$store.dispatch("pre_verification/setCustomerId", item.customer_id);
      this.$store.dispatch(
        "pre_verification/setActiveLoanId",
        item.active_loan_id
      );
    },
    openMovetoDisbursedDialog(value) {
      this.$refs.move_to_disbursed_dialog.openMovetoDisbursed(value);
    },
    openNbfcStatusDialog(value) {
      // console.log("openNbfcStatus", value);
      this.$refs.nbfc_status_dialog.openNbfcStatus(value);
    },
    openRejectPendingLeadDialog(value) {
      // console.log("openNbfcStatus", value);
      this.$refs.reject_pending_lead_dialog.openRejectPendingLead(value);
    },
    openPushToFibeDialog(value) {
      // console.log("openNbfcStatus", value);
      this.$refs.push_to_fibe.openPushToFibe(value);
    },
    openOffersList(title, list) {
      this.$refs.offers_list.openOffersListDialog(title, list);
    },
    openUpdateNbfcStatusDialog(value) {
      this.$refs.update_nbfc_loan_status_dialog.openUpdateNbfcLoanStatus(value);
    },
    openPushToLtfsDialog(value) {
      if (this.filters.precheck_status == "Precheck Applied") {
        this.$refs.push_to_ltfs_dialog.openPushToLtfs("PC", value);
      } else if (this.filters.precheck_status == "Pending By NBFC") {
        this.$refs.push_to_ltfs_dialog.openPushToLtfs("Pending By NBFC", value);
      }
       else if (this.filters.precheck_status == "Rejected By Cashpo") {
        this.$refs.push_to_ltfs_dialog.openPushToLtfs("Rejected", value);
      }
       else if (this.filters.precheck_status == "Rejected By NBFC") {
        this.$refs.push_to_ltfs_dialog.openPushToLtfs("Rejected", value);
      }
    },
    clearDocRequestStatus() {
      this.filters.document_request_status = "";
      this.getCustomerList(true);
    },
    openAssignAgent(value) {
      this.assign_agent_dialog.flag = true;
      this.assign_agent_dialog.loan_id = value.loan_id;
      this.assign_agent_dialog.agent = value.agent_assigned;
    },
    clearStatusDateFilter() {
      this.daterange = [];
      this.filters.display_status_daterange = null;
      this.filters.status_date_range = [];
      this.getCustomerList(true);
    },
    updateAgent(value) {
      let loan_id = value.loan_id;
      let agent_assigned = value.agent_assigned;

      for (let i = 0; i < this.customer_list.length; i++) {
        if (this.customer_list[i].loan_id == loan_id) {
          this.customer_list[i].agent_assigned = agent_assigned;
        }
      }
    },
    // change profile_status value on tab click
    tabClicked(value) {
      this.filters.page_number = 1;
      // let reject = value.param.search("Rejected");
      // if (reject == 0) {
      //   this.is_rejected_table = true;
      // } else {
      //   this.is_rejected_table = false;
      // }

      if (
        value.param == "Rejected By Cashpo" ||
        value.param == "Rejected By NBFC"
      ) {
        this.is_rejected_table = true;
        this.is_pending = false;
        this.is_disbursed_or_completed = false;
      } else if (
        value.param == "Disbursed By NBFC" ||
        value.param == "Completed By NBFC"
      ) {
        this.is_rejected_table = false;
        this.is_pending = false;
        this.is_disbursed_or_completed = true;
      } else if (value.param == "Pending By NBFC") {
        this.is_rejected_table = false;
        this.is_pending = true;
        this.is_disbursed_or_completed = false;
      } else {
        this.is_rejected_table = false;
        this.is_pending = false;
        this.is_disbursed_or_completed = false;
      }

      this.filters.precheck_status = value.param;
      this.tabColor = value.color;
      this.getCustomerList(true);
    },

    viewNewCustomerDetails(value) {
      this.$store.dispatch("pre_verification/setActiveLoanId", value.loan_id);
      this.$router.push({
        name: "pre-verification-details",
        params: {
          id: this.encript(value.customer_id),
        },
      });
    },

    getCurrentStatus(value) {
      let current_status = value.filter((item) => {
        if (item.status == "CURRENT") {
          return item;
        }
      });
      if (current_status[0]) {
        if (current_status[0].action) {
          return current_status[0].action;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    getNbfcList() {
      const self = this;
      let params = {};
      this.nbfc_list_loader = true;
      let look_up_key = "";
      const successHandler = (response) => {
        // console.log("nbfc list",response);
        self.nbfc_list = response.data.result;
      };
      const finallyHandler = () => {
        this.nbfc_list_loader = false;
      };

      self.GET_request(
        self,
        self.$urls.GET_NBFC_LIST,
        look_up_key,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },

    // fetch table data from server
    getCustomerList(isCancel = false) {
      const self = this;
      self.table_loading = true;
      self.customer_list = [];
      let look_up_key = "";
      if (self.filters.agent_assigned) {
        var agent_search_query = self.filters.agent_assigned.name;
      }

      let params = {
        page_number: this.filters.page_number,
        page_length: this.$keys.PAGE_LENGTH,
        search_query: this.search_query,
        precheck_status: this.filters.precheck_status
          ? this.filters.precheck_status
          : "Precheck Applied",
        from_date: this.filters.date_range[0],
        to_date: this.filters.date_range[1],
        follow_up_date: this.filters.follow_up_date,
        city: self.filters.current_city,
        state: self.filters.state,
        // agent_search_query: agent_search_query,
        loan_from_date: this.filters.status_date_range[0],
        loan_to_date: this.filters.status_date_range[1],
        is_assigned: this.filters.agent_type,
        document_type: this.filters.document_type,
        document_request_status: this.filters.document_request_status,
        disbursal_from_date: this.filters.disbursed_date_range[0],
        disbursal_to_date: this.filters.disbursed_date_range[1],
      };

      if (this.filters.alloted_nbfc) {
        params.alloted_nbfc = this.filters.alloted_nbfc;
      }
      if (this.filters.agent_assigned && this.filters.agent_type) {
        params.agent_search_query = this.filters.agent_assigned.name;
      }
      if (
        this.filters.perfios_status &&
        this.filters.precheck_status == "Approved By NBFC"
      ) {
        params.perfios_status = this.filters.perfios_status;
      }
      if (
        this.filters.nbfc_status_filter &&
        this.filters.precheck_status == "Approved By NBFC" &&
        this.filters.alloted_nbfc == "LTFS"
      ) {
        params.nbfc_status_filter = this.filters.nbfc_status_filter;
      }

      // console.log(params);
      const successHandler = (response) => {
        self.table_loading = false;
        self.customer_list = [];
        self.customer_list = response.data.result.data;
        self.tabs_title = response.data.result.allcount;
        self.total_page_count = response.data.result.total_page_count;
        if (!self.tabColor)
          self.tabColor = response.data.result.allcount[0].color;
      };

      const failureHandler = () => {
        self.table_loading = false;
      };
      const finallyHandler = () => {};

      self.GET_request(
        self,
        self.$urls.PRE_VERIFICATION_LIST,
        look_up_key,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler,
        isCancel
      );
    },

    /* fetching the cit list form server*/
    getCitylist(append_city = false) {
      const self = this;
      self.city_loading = true;
      let params = {
        search_query: this.city_search,
        page_number: this.city_page,
      };
      // console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          if (append_city)
            self.city_list = self.city_list.concat(response.data.city_list);
          else self.city_list = response.data.city_list;
        }
      };
      const finallyHandler = () => {
        self.city_loading = false;
      };

      self.request_GET(
        self,
        self.$urls.CITY_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.city_page++;
        this.getCitylist(true);
      }
    },
    endIntersectAgent(entries, observer, isIntersectingAgent) {
      if (isIntersectingAgent) {
        // this.agent_page++;
        this.getAgentList(true);
      }
    },
    formateDisplayDate() {
      if (this.filters.follow_up_date)
        this.filters.fd_display_date = this.display_date_formate(
          this.filters.follow_up_date
        );
      if (this.filters.date_range.length > 1)
        this.filters.display_daterange = this.formateDisplayDateRange(
          this.filters.date_range
        );
      if (this.filters.status_date_range.length > 1)
        this.filters.display_status_daterange = this.formateDisplayDateRange(
          this.filters.status_date_range
        );
      this.date_menu = false;
      this.status_date_menu = false;
      this.fd_date_menu = false;
      this.getCustomerList(true);
    },
    formatDisplayDisbursedDate() {
      if (this.filters.disbursed_date_range.length > 1)
        this.filters.display_disbursed_daterange = this.formateDisplayDateRange(
          this.filters.disbursed_date_range
        );
      this.disbursed_date_menu = false;
      this.status_date_menu = false;
      this.fd_date_menu = false;
      this.getCustomerList(true);
    },
    clearDateFilter() {
      this.daterange = [];
      this.filters.display_daterange = null;
      this.filters.date_range = [];
      this.getCustomerList(true);
    },
    clearDisbursedDateFilter() {
      this.disbursed_date_menu = false;
      this.filters.disbursed_date_range = [];
      this.filters.display_disbursed_daterange = "";
      this.getCustomerList(true);
    },
    formateDisplayDateRange(daterange) {
      // console.log(daterange);
      let date1 = daterange[0];
      let date2 = daterange[1];
      let to = "";
      let from = "";
      if (date1 > date2) {
        to = date1;
        from = date2;
      } else {
        to = date2;
        from = date1;
      }
      // console.log(from);
      if (!to) to = from;
      daterange[0] = from;
      daterange[1] = to;
      from = this.display_date_formate(from);
      to = this.display_date_formate(to);
      return `${from} to ${to}`;
    },
    addComment(item) {
      this.comment_dialog.comment = item.comment == " - " ? "" : item.comment;
      this.comment_dialog.id = item.customer_id;
      this.comment_dialog.follow_up_date = item.follow_up_date;
      this.comment_dialog.label = item.label;
      this.comment_dialog.name = item.name;
      this.comment_dialog.mobile = item.mobile;
      this.comment_dialog.email = item.email;
      this.comment_dialog.flag = true;
      this.$refs.comment_dialog.formateDisplayDate();
    },
    getAgentList(append_agent = false) {
      const self = this;
      self.agent_loading = true;
      let params = {
        search_query: this.agent_search,
        page_number: this.agent_page,
      };
      // console.log(params);
      const successHandler = (response) => {
        // console.log("Team list",response);
        self.agent_list = response.data.result.user_data;
        self.agent_list.unshift({
          name: "All",
        });
      };
      const finallyHandler = () => {
        self.agent_loading = false;
      };

      self.request_GET(
        self,
        self.$urls.TEAM_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    updatePrefuse(perfios, item) {
      const self = this;
      let loan_id = item.loan_id;

      let form = {
        perfios_status: perfios,
      };

      const successHandler = () => {
        item.perfios_status = perfios;
      };
      self.request_POST(
        self,
        self.$urls.CHANGE_PERFUSE_STATUS + `${loan_id}/`,
        form,
        successHandler,
        null,
        null,
        null
      );
    },
  },
};
</script>
